/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import TeamMemberCard from './TeamMemberCard';
import { List, ListItem } from './TeamMembersList.styles';
import buildSpecialistName from '../../utils/buildSpecialistName';

const TeamMembersList = ({ members }) => {
  return (
    <List>
      {members.edges.map(({ node }) => (
        <ListItem key={node.id}>
          <TeamMemberCard
            name={buildSpecialistName(
              node.title,
              node.firstname,
              node.lastname
            )}
            descriptionBlocks={node._rawDesription}
            specialty={node.specialty}
            ProfessionalTitle={node.ProfessionalTitle}
            portrait={node.portrait}
            funnyPortrait={node.funnyPortrait}
          >
            Description
          </TeamMemberCard>
        </ListItem>
      ))}
    </List>
  );
};

TeamMembersList.propTypes = {
  members: PropTypes.shape({ edges: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
};

export default TeamMembersList;

export const query = graphql`
  fragment TeamMemberBlockData on SanityTeamMemberConnection {
    edges {
      node {
        id
        firstname
        lastname
        title
        specialty
        ProfessionalTitle
        _rawDesription
        portrait {
          alt
          asset {
            fluid(maxHeight: 668, maxWidth: 480) {
              ...GatsbySanityImageFluid
            }
          }
        }
        funnyPortrait {
          alt
          asset {
            fluid(maxHeight: 668, maxWidth: 480) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
