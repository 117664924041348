// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampProfessionnels = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M3.6 77.3l1.9-5.5a7.7 7.7 0 011.4-2.6A5.8 5.8 0 019 67.7a7 7 0 012.8-.6 11.9 11.9 0 013.6.7 12 12 0 013.3 1.7 7 7 0 012 2.1 5.9 5.9 0 01.7 2.6 7.6 7.6 0 01-.4 2.9l-1.9 5.5zm15.3-1a4 4 0 00-.2-3.2A4.7 4.7 0 0016 71l-2.6-.8a4.7 4.7 0 00-3.5 0 4 4 0 00-2.1 2.3l-1 3 11.2 3.8z' />
      <path d='M28.2 62.6a6 6 0 01-1.6 1.9 4.8 4.8 0 01-2 .9 5.9 5.9 0 01-2.3 0 9.3 9.3 0 01-4.6-2.7 5.7 5.7 0 01-1.2-2 4.8 4.8 0 01-.3-2.2 5.9 5.9 0 01.8-2.3 5.5 5.5 0 011.6-1.8 4.7 4.7 0 012-.8 5.7 5.7 0 012.2 0 8 8 0 012.2 1l.9.4-4.2 7.4.3.2a3.3 3.3 0 002.4.4 3.1 3.1 0 002-1.6 3.4 3.4 0 00.6-2 4.4 4.4 0 00-.5-1.8l2-.5a4.9 4.9 0 01.6 2.6 6.5 6.5 0 01-1 3zm-9.4-5.4a3.2 3.2 0 00-.4 1.2 2.6 2.6 0 000 1.2 3 3 0 00.7 1 4 4 0 001 .8l3-5h-.3a3.4 3.4 0 00-2.3-.5 2.5 2.5 0 00-1.7 1.3z' />
      <path d='M36 51.8a6.2 6.2 0 01-2.4 1.8 6.2 6.2 0 01-2.7.5v-2.3a5.2 5.2 0 002-.3 4.1 4.1 0 001.6-1.1 2.7 2.7 0 00.8-1.6 1.4 1.4 0 00-.6-1.2 1.5 1.5 0 00-1-.4 2.2 2.2 0 00-1.3.7l-.8.8a5.9 5.9 0 01-2.8 1.5 3.1 3.1 0 01-2.6-.9 3.7 3.7 0 01-1-1.3 3.3 3.3 0 01-.3-1.4 4.2 4.2 0 01.3-1.6 6.1 6.1 0 011-1.6 6.2 6.2 0 012.2-1.7 6.2 6.2 0 012.3-.5l.1 2.2a4.3 4.3 0 00-1.5.3 3.7 3.7 0 00-1.5 1 2.4 2.4 0 00-.7 1.6 1.4 1.4 0 00.5 1 1.3 1.3 0 001.1.4A3.1 3.1 0 0030 47l.9-.8a5.5 5.5 0 012.8-1.5 3.1 3.1 0 012.4.9 3.7 3.7 0 011 1.3 3.5 3.5 0 01.3 1.6 4.5 4.5 0 01-.4 1.7 6.7 6.7 0 01-1 1.6z' />
      <path d='M39.8 30.6l2.1-1.4 1.2 1.6h.1a3.4 3.4 0 010-2.2 3.8 3.8 0 011.5-1.7 4.2 4.2 0 013.9-.8 7.7 7.7 0 013.8 3.2 7.8 7.8 0 011.7 4.6 4.2 4.2 0 01-2 3.4 3.7 3.7 0 01-2.1.8 3.4 3.4 0 01-2-.7l-.1.1 3.9 5.5-2.1 1.5zM50.2 36a2.8 2.8 0 001.3-2 3.3 3.3 0 00-.7-2.3L49.5 30a3.2 3.2 0 00-2-1.4 2.8 2.8 0 00-2.2.6 3.4 3.4 0 00-1.3 1.5 1.8 1.8 0 00.2 1.7l2.5 3.5a1.8 1.8 0 001.5.7 3.2 3.2 0 002-.6z' />
      <path d='M59.6 32.4l-5-11.2 2.3-1 1 2h.1a3.3 3.3 0 01.3-2 3.4 3.4 0 011.8-1.5l.7-.3 1 2.2-1 .5a4.5 4.5 0 00-1.9 1.4 1.6 1.6 0 00-.1 1.7l3.2 7.1z' />
      <path d='M73.6 27a6 6 0 01-2.4.3 5 5 0 01-2-.8 5.8 5.8 0 01-1.7-1.6 8 8 0 01-1.1-2.5 8.1 8.1 0 01-.4-2.6 6 6 0 01.5-2.3 4.8 4.8 0 011.4-1.7 6.2 6.2 0 014.5-1.3 4.7 4.7 0 012 .8 6 6 0 011.7 1.6 8.5 8.5 0 011.1 2.5 8.2 8.2 0 01.4 2.6 6 6 0 01-.5 2.3 5 5 0 01-1.4 1.7 5.8 5.8 0 01-2.1 1zm-.6-2a2.9 2.9 0 001.8-1.4 3.3 3.3 0 00.1-2.5l-.5-2a3.3 3.3 0 00-1.5-2.1 2.9 2.9 0 00-2.3-.2 2.8 2.8 0 00-1.8 1.4 3.3 3.3 0 000 2.5l.5 2a3.3 3.3 0 001.4 2.1 2.8 2.8 0 002.3.2z' />
      <path d='M82.1 14.3l-1.9.2-.2-2 1.9-.3-.3-2a3.4 3.4 0 01.5-2.4 3 3 0 012.2-1.1l2-.3.3 2.1-2.6.3.4 3 2.5-.2.3 2-2.5.4 1.2 10.1-2.5.3z' />
      <path d='M96.6 23.6a5.8 5.8 0 01-2.3-.5 4.8 4.8 0 01-1.8-1.4 5.7 5.7 0 01-1-2A9 9 0 0191 17a8.7 8.7 0 01.5-2.6 5.8 5.8 0 011.2-2 5 5 0 011.8-1.3 6.2 6.2 0 012.3-.4 5.6 5.6 0 012.4.5 4.7 4.7 0 011.7 1.4 5.7 5.7 0 011 2 8 8 0 01.3 2.4v1l-8.5-.2v.4a3.2 3.2 0 00.8 2.3 3.1 3.1 0 002.4 1 3.6 3.6 0 001.9-.5 4.4 4.4 0 001.3-1.3l1.5 1.5a4.9 4.9 0 01-2 1.8 6.6 6.6 0 01-3 .6zm.3-10.9a3 3 0 00-1.2.2 2.6 2.6 0 00-1 .7 2.8 2.8 0 00-.7 1 3.8 3.8 0 00-.2 1.3v.1l5.7.1V16a3.5 3.5 0 00-.7-2.3 2.5 2.5 0 00-2-.9z' />
      <path d='M110 24.6a6 6 0 01-4.6-3.1l2-1.2a5.1 5.1 0 001.2 1.5 4 4 0 001.8.8 2.6 2.6 0 001.8-.1 1.5 1.5 0 00.7-1.1 1.4 1.4 0 00-.2-1 2.2 2.2 0 00-1.2-.8l-1.1-.4a5.7 5.7 0 01-2.7-1.6 3 3 0 01-.7-2.7 3.7 3.7 0 01.6-1.5 3.4 3.4 0 011.1-1 4.3 4.3 0 011.6-.5 7 7 0 014.5 1.1 6 6 0 011.6 1.8l-1.9 1.1a4.4 4.4 0 00-1-1.1 3.7 3.7 0 00-1.7-.8 2.4 2.4 0 00-1.6.2 1.4 1.4 0 00-.7 1 1.3 1.3 0 00.3 1.1 3.2 3.2 0 001.3.8l1 .3a5.5 5.5 0 012.8 1.7 3.1 3.1 0 01.5 2.5 3.7 3.7 0 01-.6 1.6 3.6 3.6 0 01-1.2 1 4.5 4.5 0 01-1.7.5 6.7 6.7 0 01-2 0z' />
      <path d='M122.2 27.6a6.2 6.2 0 01-2.6-1.5 6.4 6.4 0 01-1.4-2.3l2-.9a5.2 5.2 0 001 1.8 4 4 0 001.8 1 2.8 2.8 0 001.7.1 1.5 1.5 0 001-1 1.4 1.4 0 000-1 2.2 2.2 0 00-1.2-1l-1-.5a5.9 5.9 0 01-2.4-2 3.1 3.1 0 01-.2-2.8 3.8 3.8 0 01.8-1.4 3.4 3.4 0 011.3-.8 4.6 4.6 0 011.6-.2 6.4 6.4 0 011.8.3 6.1 6.1 0 012.4 1.4 6.4 6.4 0 011.3 2l-2 1a4 4 0 00-.8-1.4 3.6 3.6 0 00-1.6-1 2.4 2.4 0 00-1.6 0 1.4 1.4 0 00-.8.8 1.3 1.3 0 000 1.2 3.3 3.3 0 001.3 1l1 .4A5.4 5.4 0 01128 23a3.1 3.1 0 01.1 2.6 3.7 3.7 0 01-.8 1.4 3.5 3.5 0 01-1.3.8 4.7 4.7 0 01-1.7.3 6.9 6.9 0 01-2.1-.4z' />
      <path d='M136.3 19.6l2.2 1.2-5.6 11-2.3-1.2zm2.1-1.3a1.5 1.5 0 01-.9-.9 1.4 1.4 0 01.2-1l.1-.3a1.3 1.3 0 01.8-.7 1.6 1.6 0 011.2.2 1.5 1.5 0 01.9.8 1.4 1.4 0 01-.1 1l-.2.4a1.3 1.3 0 01-.8.7 1.5 1.5 0 01-1.2-.2z' />
      <path d='M141.2 36.8a6 6 0 01-1.7-1.6 4.9 4.9 0 01-.8-2 6 6 0 010-2.3 8.4 8.4 0 011.2-2.5 8.2 8.2 0 011.8-2 6.1 6.1 0 012-1 4.8 4.8 0 012.2-.2 5.9 5.9 0 012.2.9 6 6 0 011.7 1.6 4.8 4.8 0 01.8 2 5.8 5.8 0 01-.1 2.4 8.4 8.4 0 01-1.1 2.4 8 8 0 01-1.8 2 5.9 5.9 0 01-2 1.1 4.8 4.8 0 01-2.2.1 5.9 5.9 0 01-2.2-.9zm1.1-1.8a2.9 2.9 0 002.3.5 3.3 3.3 0 002-1.6l1-1.8a3.3 3.3 0 00.7-2.5 2.8 2.8 0 00-1.3-1.8 2.9 2.9 0 00-2.2-.5 3.4 3.4 0 00-2 1.7l-1.1 1.7a3.3 3.3 0 00-.7 2.5 2.8 2.8 0 001.3 1.8z' />
      <path d='M149.2 42.3l8.4-8.9 1.9 1.8-1.4 1.4v.1a4 4 0 012-.3 3.5 3.5 0 012.2 1 3.7 3.7 0 011.2 3 5.3 5.3 0 01-1.7 3.3l-5.3 5.6-1.9-1.8 5.2-5.4c1.3-1.4 1.4-2.6.3-3.7a3.9 3.9 0 00-.8-.6 3.1 3.1 0 00-1-.3 2 2 0 00-.8 0 2 2 0 00-.8.6l-5.7 6z' />
      <path d='M159.6 53l10-7.2 1.5 2-1.6 1.3a4.1 4.1 0 012 0 3.5 3.5 0 011.9 1.5 3.7 3.7 0 01.7 3.1 5.3 5.3 0 01-2.3 3l-6.3 4.4-1.5-2 6-4.4c1.7-1 2-2.3 1-3.5a3.6 3.6 0 00-.6-.7 3 3 0 00-.8-.5 2 2 0 00-.9-.1 1.9 1.9 0 00-.9.3l-6.7 4.8z' />
      <path d='M169.7 69.8a5.8 5.8 0 01-.6-2.4 5 5 0 01.5-2.1 5.8 5.8 0 011.4-1.9 8.9 8.9 0 012.2-1.4 8.5 8.5 0 012.6-.7 5.8 5.8 0 012.3.2 4.9 4.9 0 011.9 1 6 6 0 011.4 2 5.6 5.6 0 01.5 2.4 4.5 4.5 0 01-.5 2 5.6 5.6 0 01-1.3 1.8 8.2 8.2 0 01-2 1.3l-.9.4-3.5-7.7-.3.2a3.3 3.3 0 00-1.8 1.7 3.1 3.1 0 00.2 2.5 3.5 3.5 0 001.2 1.6 4.3 4.3 0 001.8.6l-.8 2a4.9 4.9 0 01-2.4-1 6.5 6.5 0 01-2-2.5zm9.9-4.5a3.3 3.3 0 00-.8-1 2.8 2.8 0 00-1-.6 3 3 0 00-1.1-.1 3.9 3.9 0 00-1.3.3h-.2l2.4 5.3.2-.1a3.4 3.4 0 001.8-1.6 2.5 2.5 0 000-2.2z' />
      <path d='M174.2 81a2.3 2.3 0 011.8-3.3l14.4-4 .7 2.4-14.8 4.2.4 1.7-2 .5z' />
      <path d='M176 90a6.2 6.2 0 01.1-3 6.3 6.3 0 011.4-2.3l1.7 1.3a5.3 5.3 0 00-1 1.7 4 4 0 000 2 2.6 2.6 0 00.6 1.6 1.5 1.5 0 001.3.3 1.4 1.4 0 001-.5 2.1 2.1 0 00.2-1.5v-1.1a5.8 5.8 0 01.5-3.1 3 3 0 012.4-1.5 3.9 3.9 0 011.6 0 3.3 3.3 0 011.3.7 4.3 4.3 0 011 1.3 6.3 6.3 0 01.6 1.8 5.5 5.5 0 01-1.1 4.8l-1.8-1.3a4.1 4.1 0 00.8-1.4 3.8 3.8 0 000-1.8 2.4 2.4 0 00-.6-1.4 1.3 1.3 0 00-1.2-.3 1.3 1.3 0 00-1 .6 3.3 3.3 0 00-.3 1.5v1.1a5.4 5.4 0 01-.6 3.2 3.1 3.1 0 01-2.1 1.3 3.8 3.8 0 01-1.7 0 3.6 3.6 0 01-1.4-.7 4.6 4.6 0 01-1-1.4 6.7 6.7 0 01-.6-1.9z' />
      <path d='M179 113.4v-.1a3.4 3.4 0 01-1.5-1.4 3.8 3.8 0 01-.5-2.2 4.3 4.3 0 012-3.5 9.4 9.4 0 019.5.7 4.3 4.3 0 011.4 3.7 3.8 3.8 0 01-.8 2.1 3.3 3.3 0 01-1.8 1.2v.1l7.2.5-.2 2.6-17.4-1.3.2-2.6zm.2-2.8a3.3 3.3 0 00.4 2 1.8 1.8 0 001.5 1l4.2.3a1.8 1.8 0 001.5-.7 3.3 3.3 0 00.8-2 2.8 2.8 0 00-.7-2.2 3.2 3.2 0 00-2.2-1l-2.2-.1a3.2 3.2 0 00-2.3.6 2.8 2.8 0 00-1 2.1z' />
      <path d='M174.4 125.2a6 6 0 011-2.2 4.8 4.8 0 011.8-1.3 5.7 5.7 0 012.2-.5 8.5 8.5 0 012.7.3 8.8 8.8 0 012.4 1.1 5.9 5.9 0 011.7 1.6 5 5 0 01.7 2 6 6 0 01-.2 2.4 5.5 5.5 0 01-1 2.2 4.5 4.5 0 01-1.8 1.3 5.8 5.8 0 01-2.2.4 8.1 8.1 0 01-2.3-.3l-1-.2 2.3-8.2h-.4a3.3 3.3 0 00-2.4.1 3.1 3.1 0 00-1.5 2 3.5 3.5 0 000 2 4.3 4.3 0 00.9 1.7l-1.9 1a4.8 4.8 0 01-1.2-2.4 6.5 6.5 0 01.2-3zm10.4 3a3.1 3.1 0 00.1-1.2 2.6 2.6 0 00-.4-1.1 3 3 0 00-.8-.9 3.7 3.7 0 00-1.2-.5h-.1l-1.6 5.5h.2a3.3 3.3 0 002.4 0 2.5 2.5 0 001.4-1.8zm3 1.2l1.2-1.4 3.5 3.2-1.7 1.9z' />
      <path d='M170.6 136.5l13 1.3-1.1 2.3-5.9-.6-4.4-.7v.2l3.3 2.9 4.3 4-1 2.3-9.4-9z' />
      <path d='M163 149.6a5.7 5.7 0 011.6-1.6 4.8 4.8 0 012.1-.7 5.9 5.9 0 012.3.2 8.3 8.3 0 012.4 1.2 8.4 8.4 0 012 1.9 6.1 6.1 0 011 2 5 5 0 010 2.3 6.3 6.3 0 01-2.7 3.8 4.8 4.8 0 01-2.1.7 5.8 5.8 0 01-2.3-.3 8.5 8.5 0 01-2.4-1.2 8.2 8.2 0 01-2-1.9 5.8 5.8 0 01-1-2 4.8 4.8 0 010-2.3 5.7 5.7 0 011-2.1zm1.7 1.2a3 3 0 00-.6 2.2 3.4 3.4 0 001.5 2.1l1.7 1.2a3.3 3.3 0 002.4.8 2.9 2.9 0 002-1.3 2.9 2.9 0 00.5-2.2 3.3 3.3 0 00-1.5-2l-1.7-1.3a3.3 3.3 0 00-2.5-.7 2.8 2.8 0 00-1.8 1.2z' />
      <path d='M153.3 164.1h.1a4.9 4.9 0 01-.3-1 2.8 2.8 0 010-1 3 3 0 01.4-1 4.3 4.3 0 01.8-1 3.7 3.7 0 013-1.2 5.2 5.2 0 013.2 1.8l5.4 5.7-1.9 1.7-5.2-5.4c-1.3-1.4-2.5-1.5-3.6-.4a3.7 3.7 0 00-.7.7 2.4 2.4 0 00-.3.9 2 2 0 000 .9 1.9 1.9 0 00.5.8l5.7 6-1.9 1.8-8.5-9 1.9-1.7z' />
      <path d='M142.3 170.1a5.8 5.8 0 012.2-.9 5 5 0 012.2.1 5.7 5.7 0 012 1.1 9.2 9.2 0 012.8 4.5 5.6 5.6 0 01.2 2.3 4.8 4.8 0 01-.8 2 6 6 0 01-1.8 1.7 5.5 5.5 0 01-2.2.9 4.8 4.8 0 01-2.2-.2 5.9 5.9 0 01-1.9-1 8.5 8.5 0 01-1.6-2l-.5-.7 7.1-4.6-.2-.3a3.3 3.3 0 00-2-1.5 3.1 3.1 0 00-2.5.5 3.5 3.5 0 00-1.3 1.5 4.3 4.3 0 00-.4 1.8l-2.1-.4a5 5 0 01.7-2.6 6.4 6.4 0 012.3-2.2zm5.8 9.1a3 3 0 00.9-.8 2.9 2.9 0 00.4-1 3.2 3.2 0 000-1.3 3.6 3.6 0 00-.5-1.2l-.1-.1-4.8 3 .1.3a3.4 3.4 0 001.9 1.5 2.5 2.5 0 002.1-.4zm1.5 2.9l1.7-.2.5 4.7-2.5.3z' />
      <path d='M130.6 176.7a6.3 6.3 0 013-.7 6.6 6.6 0 012.5.7l-.9 2a5.2 5.2 0 00-1.9-.5 4 4 0 00-2 .4 2.7 2.7 0 00-1.3 1.1 1.4 1.4 0 000 1.3 1.4 1.4 0 00.7.8 2.2 2.2 0 001.5 0l1-.4a5.7 5.7 0 013.2-.2 3 3 0 012 1.9 3.7 3.7 0 01.4 1.6 3.4 3.4 0 01-.4 1.4 4.3 4.3 0 01-1 1.3 7 7 0 01-4.2 1.6 6.4 6.4 0 01-2.3-.5l.8-2a4.1 4.1 0 001.5.4 3.6 3.6 0 001.8-.4 2.4 2.4 0 001.2-1 1.4 1.4 0 000-1.2 1.3 1.3 0 00-.8-.8 3.3 3.3 0 00-1.5.1l-1.1.4a5.4 5.4 0 01-3.2.1 3 3 0 01-1.8-1.8 3.7 3.7 0 01-.4-1.6 3.6 3.6 0 01.4-1.5 4.7 4.7 0 011-1.4 6.8 6.8 0 011.8-1.1z' />
      <path d='M97 193.3a89.6 89.6 0 01-89.5-89.5 90.6 90.6 0 01.8-11.6l2.2.2a90 90 0 00-.8 11.4 87.4 87.4 0 00108.7 84.7l.6 2a89.6 89.6 0 01-22 2.8z' />
    </RoundContainer>
    <path d='M80.8 96v18.3a15.5 15.5 0 0012.5 15.2h.2l3.7.1a10.5 10.5 0 002.3-.1c7.5-1.8 12.7-8 12.7-15.2V89a4.2 4.2 0 00-4.1-4.2 4 4 0 00-1.7.4h-.2v-2a4.2 4.2 0 00-4-4.3 4 4 0 00-1.8.4h-.1v-.1a4.1 4.1 0 00-8.2 0v.1a4 4 0 00-1.9-.4 4.2 4.2 0 00-4 4.2v11l-.7-.5a3.3 3.3 0 00-1.8-.4 3.2 3.2 0 00-1.5.3 2.8 2.8 0 00-1.5 2.4zm2.2 0a.5.5 0 01.3-.4 1 1 0 01.9 0l1 .7a4.5 4.5 0 011.8 3.3v8a1.2 1.2 0 00.3.7 1.2 1.2 0 00.4.3s6.7 3 6.7 8a1.1 1.1 0 002.3 0c0-1.8-.7-6.4-7.4-9.7v-7.3a6.1 6.1 0 00-.8-3 .6.6 0 000-.1V83.2a1.8 1.8 0 113.6 0v16.2a1.1 1.1 0 002.3 0V80a1.8 1.8 0 113.6 0v19.4a1.1 1.1 0 102.3 0V83.2a1.8 1.8 0 113.6 0v6.4a.7.7 0 000 .2v11.6a1.1 1.1 0 002.3 0V89a1.8 1.8 0 113.7 0v25.2c0 6.1-4.5 11.5-11 13a8.7 8.7 0 01-1.6.1 45 45 0 01-3.6-.2A13.2 13.2 0 0183 114.3z' />
  </svg>
);

export default StampProfessionnels;
