// vendors
import styled from 'styled-components';
import { rem } from 'polished';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';

export const List = styled.ul`
  display: grid;
  grid-gap: 32px 64px;
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));

  margin: ${rem(72)} 0;
  padding: 0;

  list-style: none;

  ${greaterThan(breakpoints.spacings[1])} {
    grid-gap: 56px 168px;
  }

  ${greaterThan(breakpoints.spacings[2])} {
    grid-gap: 88px 224px;

    margin: ${rem(168)} 0 ${rem(228)};
  }
`;

export const ListItem = styled.li``;
