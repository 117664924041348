import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { em } from 'polished';
import colors from '../../../styles/colors';
import breakpoints from '../../../styles/breakpoints';
import responsiveStyle from '../../../utils/responsiveStyle';

export const TitleContainer = styled.div``;

export const NameWrapper = styled.p`
  margin: 0;

  line-height: ${48 / 38};

  ${responsiveStyle('fontSize', [20, 38], breakpoints.fonts)};

  ${responsiveStyle('marginTop', [32, 64], breakpoints.fonts)};
`;

export const ProfessionalTitleWrapper = styled.p`
  margin-top: 0;

  line-height: ${29 / 19};

  ${responsiveStyle('fontSize', [13, 19], breakpoints.fonts)};
`;

export const SpecialtyWrapper = styled.p`
  margin-bottom: 0;

  color: ${colors.brownGrey};
  line-height: ${40 / 30};

  ${responsiveStyle('fontSize', [15, 30], breakpoints.fonts)};
`;

export const bodyStyles = css`
  ${responsiveStyle('fontSize', [13, 16], breakpoints.fonts)};
`;

export const StyledPicture = styled(Img)`
  filter: grayscale(100%);
`;

export const CTAButton = styled.button`
  padding: 1em 0;

  color: ${colors.azure};

  background: none;
  border: 0;

  cursor: pointer;

  appearance: none;

  ${responsiveStyle('fontSize', [15, 24], breakpoints.fonts)}

  svg {
    width: ${em(12, 24)};
    margin-left: ${em(15, 24)};
  }

  :hover,
  :focus {
    background-color: ${colors.veryLightPink};
  }
`;

export const CTAWrapper = styled.div`
  text-align: right;
`;
