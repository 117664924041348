import React from 'react';

const IconArrow = (props) => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 10.98 18.3'
    {...props}
  >
    <polyline
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      points='0.75 17.58 9.54 9.18 0.69 0.72'
    />
  </svg>
);

export default IconArrow;
