// vendors
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../../Accordion/AccordionItem/AccordionItem';
import BlockContent from '../../BlockContent/BlockContent';
import {
  bodyStyles,
  NameWrapper,
  ProfessionalTitleWrapper,
  SpecialtyWrapper,
  StyledPicture,
  CTAButton,
  CTAWrapper,
} from './TeamMemberCard.styles';
import { TitleWrapper } from '../../Accordion/AccordionItem/AccordionItem.styles';
import IconArrow from '../../../images/IconArrow';
import { useContactForm } from '../../../contexts/contactFormContext';
import { speed } from '../../../styles/animation';

const TeamMemberCard = ({
  name,
  descriptionBlocks,
  specialty,
  ProfessionalTitle,
  portrait,
  funnyPortrait,
}) => {
  const [open, setOpen] = useState(false);
  const { open: openContactFormModal } = useContactForm();

  const handleOpenContactFormModal = () => {
    openContactFormModal({ specialist: name });
  };

  const TitleSection = (
    <TitleWrapper>
      <NameWrapper>{name}</NameWrapper>

      <ProfessionalTitleWrapper>
        {ProfessionalTitle.join(', ')}
      </ProfessionalTitleWrapper>

      <SpecialtyWrapper>{specialty}</SpecialtyWrapper>
    </TitleWrapper>
  );

  const handleToggleClick = () => setOpen(!open);

  const source = useMemo(() => {
    return {
      ...portrait?.asset?.fluid,
      sizes: `
          (min-width: 648px) ${(324 / 768) * 100}vw,
          (min-width: 768px) ${(384 / 992) * 100}vw,
          (min-width: 992px) ${(476 / 1280) * 100}vw,
          (min-width: 1280px) ${(488 / 1440) * 100}vw,
          (min-width: 1440px) 488px,
          ${(592 / 648) * 100}vw`,
    };
  }, [portrait]);

  const sourceAlt = useMemo(() => {
    return {
      ...funnyPortrait?.asset?.fluid,
      sizes: `
          (min-width: 648px) ${(324 / 768) * 100}vw,
          (min-width: 768px) ${(384 / 992) * 100}vw,
          (min-width: 992px) ${(476 / 1280) * 100}vw,
          (min-width: 1280px) ${(488 / 1440) * 100}vw,
          (min-width: 1440px) 488px,
          ${(592 / 648) * 100}vw`,
    };
  }, [funnyPortrait]);

  return (
    <div>
      <div
        css={`
          position: relative;
        `}
      >
        <StyledPicture fluid={source} alt={portrait?.alt} />

        <StyledPicture
          fluid={sourceAlt}
          alt={funnyPortrait?.alt}
          css={`
            position: absolute !important;
            inset: 0;

            opacity: 0;
            transition: opacity ${speed.fast};

            will-change: opacity;

            :hover {
              opacity: 1;
            }
          `}
        />
      </div>

      <AccordionItem
        renderTitle={TitleSection}
        open={open}
        onToggleClick={handleToggleClick}
      >
        <BlockContent blocks={descriptionBlocks} css={bodyStyles} />

        <CTAWrapper>
          <CTAButton onClick={handleOpenContactFormModal}>
            Contacter
            <IconArrow />
          </CTAButton>
        </CTAWrapper>
      </AccordionItem>
    </div>
  );
};

TeamMemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  descriptionBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  specialty: PropTypes.string.isRequired,
  ProfessionalTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  portrait: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }).isRequired,
  funnyPortrait: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }),
};
TeamMemberCard.defaultProps = {
  funnyPortrait: undefined,
};

export default TeamMemberCard;
