// vendors
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  HeaderContainer,
  TitleWrapper,
  ActionButton,
  BodyWrapper,
  BodyContainer,
} from './AccordionItem.styles';
import IconPlus from '../../../images/IconPlus';

const AccordionItem = ({ renderTitle, children, open, onToggleClick }) => {
  return (
    <Container open={open}>
      <HeaderContainer onClick={onToggleClick}>
        <TitleWrapper>{renderTitle}</TitleWrapper>

        <ActionButton type='button' title='Développer/réduire'>
          <IconPlus />
        </ActionButton>
      </HeaderContainer>

      <BodyContainer open={open}>
        <BodyWrapper>{children}</BodyWrapper>
      </BodyContainer>
    </Container>
  );
};

AccordionItem.propTypes = {
  renderTitle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

AccordionItem.defaultProps = {
  open: false,
  onToggleClick: () => {},
};

export default AccordionItem;
