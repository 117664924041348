// vendors
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HeroWithText from '../components/HeroWithText';
import { h1Style, introStyle } from '../styles/global';
import TeamMembersList from '../components/TeamMembersList/TeamMembersList';
import responsiveStyle from '../utils/responsiveStyle';
import breakpoints from '../styles/breakpoints';
import StampProfessionnels from '../images/StampProfessionnels';
import colors from '../styles/colors';
import SEO from '../components/SEO';

const TeamListSection = styled.section`
  max-width: ${1200 + 240}px;
  margin: auto;
  padding: 0;

  ${responsiveStyle('paddingLeft', [28, 80, null, 120], breakpoints.spacings)};
  ${responsiveStyle('paddingRight', [28, 80, null, 120], breakpoints.spacings)};
`;

const Stamp = styled(StampProfessionnels)`
  color: ${colors.azure};
`;

const EquipePage = ({ data }) => {
  const {
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
    allSanityTeamMember,
  } = data;
  return (
    <Layout>
      <SEO
        title='Équipe'
        description='Nos spécialistes des cliniques Maxillo Québec Lebourgneuf et Lévis sont dévoués et sauront s’occuper de vous et vous traiter avec humanisme, empathie et passion.'
      />

      <article>
        <HeroWithText
          stamp={<Stamp />}
          pictureSmall={headerImageDesktop}
          pictureLarge={headerImageDesktop}
        >
          <h1 css={h1Style}>L’équipe de&nbsp;spécialistes Maxillo Québec</h1>

          <p css={introStyle}>
            Nos chirurgiens spécialistes des cliniques Maxillo Québec ont suivi
            des formations spécialisées dans le but de vous offrir un éventail
            de traitements des plus poussés, actuels et à la hauteur de vos
            attentes.
          </p>

          <p>
            Les spécialistes de la clinique Maxillo Québec sont des
            professionnels dévoués qui sauront s’occuper de vous et vous traiter
            avec humanisme, empathie et passion. Votre chirurgien buccal et
            maxillo-facial de la clinique Maxillo Québec vous écoutera et vous
            portera toute leur attention, et ce, dans la discrétion et la
            confidentialité la plus totale.
          </p>
        </HeroWithText>

        <TeamListSection>
          <TeamMembersList members={allSanityTeamMember} />
        </TeamListSection>
      </article>
    </Layout>
  );
};

EquipePage.propTypes = {
  data: PropTypes.shape({
    headerImageMobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    headerImageDesktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    allSanityTeamMember: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default EquipePage;

export const query = graphql`
  query {
    headerImageDesktop: file(name: { eq: "img-header-desktop-equipe" }) {
      ...HeroWithTextLargePictureData
    }

    headerImageMobile: file(name: { eq: "img-header-mobile-equipe" }) {
      ...HeroWithTextSmallPictureData
    }

    allSanityTeamMember(sort: { fields: sort, order: ASC }) {
      ...TeamMemberBlockData
    }
  }
`;
