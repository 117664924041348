// vendors
import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HeaderContainer = styled.div`
  display: flex;

  align-items: flex-end;

  margin-bottom: 24px;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${colors.veryLightPink};
  }
`;

export const BodyWrapper = styled.div`
  color: ${colors.black};
`;

export const BodyContainer = styled.div``;

export const TitleWrapper = styled.div`
  flex-grow: 1;
`;

export const ActionButton = styled.button`
  flex-basis: 24px;

  min-width: 24px;
  min-height: 24px;
  margin: 0;
  padding: 0;

  line-height: 0;

  background: none;

  border: 0;
  cursor: pointer;

  appearance: none;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto ${({ open }) => (open ? '1fr' : '0px')};

  overflow: hidden;

  color: ${({ open }) => (open ? colors.azure : 'inherit')};

  border-bottom: 2px solid currentColor;

  transition: grid 200ms, color 200ms;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${BodyWrapper} {
    transform: translateY(${({ open }) => (open ? '0px' : '-24px')});
    opacity: ${({ open }) => (open ? '1' : '0')};

    transition: transform 200ms, opacity 400ms;

    @media screen and (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  ${ActionButton} {
    transform: rotateZ(${({ open }) => (open ? '45deg' : '0')});

    transition: transform 200ms;
  }
`;
